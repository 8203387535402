exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-compare-uptime-sorry-vs-statuspage-js": () => import("./../../../src/pages/compare/uptime-sorry-vs-statuspage.js" /* webpackChunkName: "component---src-pages-compare-uptime-sorry-vs-statuspage-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-detailed-status-page-js": () => import("./../../../src/pages/detailed-status-page.js" /* webpackChunkName: "component---src-pages-detailed-status-page-js" */),
  "component---src-pages-error-pages-js": () => import("./../../../src/pages/error-pages.js" /* webpackChunkName: "component---src-pages-error-pages-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intercom-messenger-app-js": () => import("./../../../src/pages/intercom-messenger-app.js" /* webpackChunkName: "component---src-pages-intercom-messenger-app-js" */),
  "component---src-pages-monitoring-automation-js": () => import("./../../../src/pages/monitoring-automation.js" /* webpackChunkName: "component---src-pages-monitoring-automation-js" */),
  "component---src-pages-notices-general-js": () => import("./../../../src/pages/notices/general.js" /* webpackChunkName: "component---src-pages-notices-general-js" */),
  "component---src-pages-notices-incidents-js": () => import("./../../../src/pages/notices/incidents.js" /* webpackChunkName: "component---src-pages-notices-incidents-js" */),
  "component---src-pages-notifications-js": () => import("./../../../src/pages/notifications.js" /* webpackChunkName: "component---src-pages-notifications-js" */),
  "component---src-pages-powered-by-js": () => import("./../../../src/pages/powered-by.js" /* webpackChunkName: "component---src-pages-powered-by-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-private-status-page-js": () => import("./../../../src/pages/private-status-page.js" /* webpackChunkName: "component---src-pages-private-status-page-js" */),
  "component---src-pages-security-and-performance-js": () => import("./../../../src/pages/security-and-performance.js" /* webpackChunkName: "component---src-pages-security-and-performance-js" */),
  "component---src-pages-simple-status-page-js": () => import("./../../../src/pages/simple-status-page.js" /* webpackChunkName: "component---src-pages-simple-status-page-js" */),
  "component---src-pages-status-api-js": () => import("./../../../src/pages/status-api.js" /* webpackChunkName: "component---src-pages-status-api-js" */),
  "component---src-pages-status-page-js": () => import("./../../../src/pages/status-page.js" /* webpackChunkName: "component---src-pages-status-page-js" */),
  "component---src-pages-testimonials-20240723-fortra-cybersecurity-status-js": () => import("./../../../src/pages/testimonials/20240723-fortra-cybersecurity-status.js" /* webpackChunkName: "component---src-pages-testimonials-20240723-fortra-cybersecurity-status-js" */),
  "component---src-pages-testimonials-20240910-postmark-email-delivery-status-js": () => import("./../../../src/pages/testimonials/20240910-postmark-email-delivery-status.js" /* webpackChunkName: "component---src-pages-testimonials-20240910-postmark-email-delivery-status-js" */),
  "component---src-pages-vulnerability-disclosure-js": () => import("./../../../src/pages/vulnerability-disclosure.js" /* webpackChunkName: "component---src-pages-vulnerability-disclosure-js" */)
}

